.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0rem 3rem;
}

@media only screen and (min-width: 800px) {
  main {
    padding-bottom: 2.5em;
    padding-top: 1.5em;
  }
}

@media only screen and (max-width: 800px) {
  main {
    padding-bottom: 2.5em;
    padding-top: 1.5em;
  }
}