@font-face {
  font-family: CrimsonText;
  src: url("../../assets/fonts/CrimsonText-Regular.ttf");
}

@font-face {
  font-family: Karla;
  src: url("../../assets/fonts/Karla-Regular.ttf");
}

.title {
  font-size: 42px;
  font-weight: 700;
  font-style: italic;
  font-variant: normal;
  letter-spacing: -5px;
  position: relative;
}

.title a {
  color: black;
  text-decoration: none;
}

.articlesList, .articlesList>li {
  list-style: none;
  padding: 0;
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5em 3vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 790px;
  font-family: Karla;
  -ms-flex-positive: 1;
  -ms-flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-direction: column;
}

.container h3 {
  margin: inherit;
}

.fixed {
  width: 15%;
}

.flexItem {
  padding-top: 15px;
  float: right;
}

.footer {
  /* padding-top: 2.5em; */
}

@media only screen and (max-width: 800px) {
  .flexItem {
    width: 100%;
  }
  .title {
    padding-top: 0px;
    padding-left: 50px;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .bannerImage {
    width: 100%;
    height: 230px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .divider:after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    width: 100%;
    padding-top: 20px;
    border-bottom: 0.5px solid #B933AD
  }
}