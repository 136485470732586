@font-face {
  font-family: CrimsonText;
  src: url("../../assets/fonts/CrimsonText-Regular.ttf");
}

@font-face {
  font-family: Karla;
  src: url("../../assets/fonts/Karla-Regular.ttf");
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5em 3vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 790px;
  font-family: Karla;
  -ms-flex-positive: 1;
  -ms-flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-direction: column;
}

.container h1 {
  font-size: 2em;
}

.container h2 {
  font-size: 1.5em;
}

.container h3 {
  margin: inherit;
}

.flexItem {
  padding-top: 15px;
  float: right;
}

ul {
  list-style: disc;
}

.title>a {
  text-decoration: none;
}

.header .title {
  font-family: CrimsonText;
  font-weight: 900;
  line-height: 1.1;
  font-size: 2.66667rem;
  text-rendering: optimizeLegibility;
  margin-top: 0rem;
  margin-bottom: 0;
}

.header .title:after {
  /* This is necessary for the pseudo element to work. */
  content: "";
  /* This will put the pseudo element on its own line. */
  display: block;
  width: 60px;
  padding-top: 20px;
  border-bottom: 0.5px solid #B933AD;
}

.header .title>a {
  --primary-color: black;
}

@media only screen and (max-width: 800px) {
  .header .title {
    font-size: 30px !important;
  }
  .flexItem {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  body {
    font: 400 15px/1.7 CrimsonText !important;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.footer {
  border-top: 1px solid #eee;
  /* border-bottom: 1px solid #eee; */
  padding-top: 2.5em;
  padding-bottom: 1.0em;
}

.footer .title {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}

.footer .title>a {
  color: var(--primary-color-light);
}

.links {
  display: flex;
  justify-content: space-between;
}

.links .next {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.content {
  font-size: 112.5%;
  line-height: 1.66667;
  color: #1c1c1c;
}

.content hr {
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

.content h3 {
  margin: inherit;
}

.content > p:first-child {
  margin-top: 1.75rem;
}

/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/

.content code, .content pre {
  color: #f7fcff;
  font-family: Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

/* Code blocks */

.content pre {
  overflow: auto;
  padding: 1em;
}

.content :not(pre)>code, .content pre {
  /* code background */
  background: black;
}

.content pre::-moz-selection, .content pre::selection {
  background: hsl(207, 4%, 16%);
}

/* Text Selection colour */

.content pre::-moz-selection, .content pre ::-moz-selection {
  background: #423022;
}

.content pre::selection, .content pre ::selection {
  background: #423022;
}

/* Inline code */

.content :not(pre)>code {
  border-radius: .3em;
  background: rgba(255, 225, 0, 0.24);;
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}

.content a>code {
  color: black;
}

.content :global(.highlighted-line) {
  background-color: #236c75;
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.content :global(.token.attr-name) {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.content :global(.token.comment) {
  color: #c1b9b9;
  font-style: italic;
}

.content :global(.token.shebang) {
  color: grey;
  font-style: italic;
}

.content :global(.token.string), .content :global(.token.url) {
  color: #a0c3ff;
}

.content :global(.token.variable) {
  color: rgb(214, 222, 235);
}

.content :global(.token.number) {
  color: #91b9ff;
}

.content :global(.token.function) {
  color: #f3e056;
}

.content :global(.token.environment.constant) {
  color: rgb(3, 246, 35);
  font-weight: 900;
}

.content :global(.token.builtin), .content :global(.token.char), .content :global(.token.constant) {
  color: #eeb926;
}

.content :global(.token.punctuation) {
  color: #7cc7cd;
}

.content :global(.token.selector), .content :global(.token.doctype) {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.content :global(.token.builtin.class-name) {
  color: #f3e056;
  font-style: 'italic';
}

.content :global(.token.class-name) {
  color: #46b9c8
}

.content :global(.token.operator) {
  color: #b933ad;
}

.content :global(.token.tag), .content :global(.token.keyword) {
  color: #f79ba2;
}

.content :global(.token.boolean) {
  color: rgb(255, 88, 116);
}

.content :global(.token.property) {
  color: rgb(128, 203, 196);
}

.content :global(.token.namespace) {
  color: #ede9da;
}
