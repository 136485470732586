@font-face {
  font-family: CrimsonText;
  src: url("../../assets/fonts/CrimsonText-Regular.ttf");
}

@font-face {
  font-family: Karla;
  src: url("../../assets/fonts/Karla-Regular.ttf");
}

.ArticleSummary {
  padding-top: 1px;
  padding-bottom: 1px;
  margin-bottom: 1em;
  position: relative;
}

.ArticleSummary h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 35px;
  line-height: 1.1;
  font-family: CrimsonText;
}

.ArticleSummary a {
  text-decoration: none;
}

.ArticleSummary p {
  font-size: 12px;
  font-style: italic;
  color: #6f6a72;
  font-family: Karla;
}

@media only screen and (max-width: 800px) {
  .ArticleSummary h2 {
    font-size: 30px !important;
  }
  .ArticleSummary p {
    font-size: 12px !important;
  }
}