.header {
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0.5em 3vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 790px;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
}

.navBar {
  border-bottom: 0.5px solid #b933ad;
  clear: both;
  display: flex;
  font-size: 18px;
  font-family: Karla;
  justify-content: space-between;
  padding-bottom: 35px;
  padding-top: 25px;
}

.navBar img {
  height: 75px;
}

.navBar a {
  background-color: transparent;
  color: black;
  display: table-cell;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
