@font-face {
  font-family: CrimsonText;
  src: url("./assets/fonts/CrimsonText-Regular.ttf");
}

html, body {
  position: relative;
  overflow-x: hidden;
}

:root {
  --primary-color: black;
  --primary-color-light: #001AC9;
}

:global * {
  box-sizing: border-box;
}

:global html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75;
  font-family: CrimsonText;
}

:global body {
  font-kerning: normal;
  font-family: CrimsonText;
  font: 400 16px/1.7 CrimsonText;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
}

:global(pre), :global(code) {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}

:global pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

:global a {
  color: rgb(5, 3, 129);
}

:global a:hover {
  color: rgb(10, 9, 82);
  text-decoration: none;
}

:global h1>a, :global h2>a, :global h3>a {
  color: black;
}

:global h1>a:hover, :global h2>a:hover, :global h3>a:hover {
  color: black;
  background-color: transparent;
}

:global p, :global pre, :global ul, :global ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}