@font-face {
  font-family: Karla;
  src: url("../../assets/fonts/Karla-Regular.ttf");
}

.timestamp {
  color: #523e35;
  font-size: 0.77778rem;
  letter-spacing: 1px;
  line-height: 1.5;
  margin-bottom: 0.75em;
  font-family: Karla;
}